<template>
  <div>
     <nav class="navbar">
      <div class="navbar-wrapper">
        <ButtonIcon @click.native="goToPreviousPage"/>
        <ProgressIndicator
            :currentPage="currentStep"
            :totalPages="totalPages"
        />
      </div>
    </nav>
    <form @submit.prevent="submit" class="form">
      <div class="form-page">
        <h2 class="form-page-title" v-t="'page.trip_request.accommodation.title'"/>
        <div class="accommodations">
          <div class="mb-8">
            <label class="checkbox-group-label" v-t="'page.trip_request.accommodation.label'"/>
            <div
                class="flex flex-wrap mt-4"
                v-for="(accommodation, index) in accommodations"
                :key="index"
            >
              <label class="flex items-center w-full" :for="accommodation.value">
                <input
                    type="checkbox"
                    class="form-checkbox"
                    :id="accommodation.value"
                    v-model="form.accommodations"
                    :value="accommodation.value"
                    @checked="$v.form.accommodations.$touch()"
                    :ref="accommodation.value"
                    :name="accommodation.value"
                    @change="setAccommodation(accommodation.value)"
                />
                <span
                    class="ml-3 text-sm text-black-base border-black-lightest"
                >{{ $t(accommodation.name) }}</span
                >
              </label>
            </div>

            <ErrorMessage
              v-if="!$v.form.accommodations.required && $v.form.accommodations.$error"
              :error-msg="$t('trip_request.form_5.input_1.error')"
            />
          </div>

          <template v-if="numOfTravelers > 1">
            <Banner
              type="warning"
              class="mb-6"
              :title="`${$t('trip_request.form_5.input_3.banner.title')}`"
              :description="`${$t(
                'trip_request.form_5.input_3.banner.description'
              )}`"
            />
            <SelectDropdown
              :optionsList="rooms"
              :description="$t('trip_request.form_5.input_2.placeholder')"
              v-model="form.singleRoom"
              :show-label="true"
              :label="$t('trip_request.form_5.input_2.label')"
              :disabled="loading"
              :errorMsg="roomErrMsg"
              :showErrMsg="$v.form.singleRoom.$error"
              ref="singleRooms"
              @blur="$v.form.singleRoom.$touch()"
              @change.native="changeNumberOfSingleRoom()"
            />
          </template>

          <template v-if="numOfTravelers === 1">
            <Banner
            type="warning"
              class="mb-6"
              :title="$t('trip_request.form_5.input_3.banner.title')"
              :description="$t('trip_request.form_5.input_3.banner.description')"
            />

            <label class="label" v-t="'page.trip_request.check_into_single_room.label'"/>
            <RadioButtonGroup
              @blur="$v.form.singleRoom.$touch()"
              :options="options.singleRoom"
              v-model="form.singleRoom"
              :disabled="loading"
              ref="singleRoom"
              :isError="$v.form.singleRoom.$error"
              @change.native="isSingleRoom(form.singleRoom)"
            />
            <ErrorMessage
              v-if="$v.form.singleRoom.$error && !$v.form.singleRoom.required"
              :error-msg="$t('trip_request.form_5.input_3.error')"
            />
            
          </template>
          <SelectDropdown
            :optionsList="meal_plans"
            :description="`${$t('trip_request.form_5.input_4.placeholder')}`"
            :show-label="true"
            :label="`${$t('trip_request.form_5.input_4.label')}`"
            v-model="form.meal"
            :disabled="loading"
            :errorMsg="mealErrMsg"
            :showErrMsg="$v.form.meal.$error"
            class="mt-8"
            ref="mealPlan"
            @blur="$v.form.meal.$touch()"
            @change.native="changeMealPlan()"
          />
        </div>
        <ButtonPrimary :text="$t('trip_request.button')" class="desktop-cta"/>
      </div>

      <div class="bottom-nav">
        <div class="bottom-nav-wrapper">
          <ButtonPrimary
            class="block h-12 px-8 w-full"
            :text="`${$t('trip_request.button')}`"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Banner from "@/components/banners/Banner";
import SelectDropdown from "@/components/dropdowns/SelectDropdown";
import minLength from "vuelidate/lib/validators/minLength";
import {mapGetters, mapActions} from "vuex";
import RadioButtonGroup from "@/components/inputs/RadioButtonGroup";
import ButtonIcon from "@/components/buttons/ButtonIcon";
import ErrorMessage from "@/components/ErrorMessage";

const { required } = require("vuelidate/lib/validators");

export default {
  name: "Accommodation",
  components: {
    ButtonIcon,
    Banner,
    RadioButtonGroup,
    SelectDropdown,
    ErrorMessage
},
  data() {
    return {
      showLayer: false,
      showLang: false,
      options: {
        singleRoom: [
          {
            label: this.$t("trip_request.form_5.input_3.values.value_1"),
            name: "room",
            value: 1,
          },
          {
            label: this.$t("trip_request.form_5.input_3.values.value_2"),
            name: "room",
            value: 0,
          },
        ],
      },
      form: {
        singleRoom: "",
        meal: "",
        accommodations: [],
      },
      accommodations: [
        {name:"trip_request.form_5.input_1.values.value_1", value:"Homestay or guesthouse"},
        {name:"trip_request.form_5.input_1.values.value_2", value:"Simple hotels (clean and cheap)"},
        {name:"trip_request.form_5.input_1.values.value_3", value:"Middle class hotels (equivalent to 3 stars)"},
        {name:"trip_request.form_5.input_1.values.value_4", value:"Charm and luxury hotels (4 stars or more)"},
      ],
      loading: false,
      meal_plans: [
        {
          name: this.$t("trip_request.form_5.input_4.values.value_1"),
          value: "Breakfast",
        },
        {
          name: this.$t("trip_request.form_5.input_4.values.value_2"),
          value: "Half board",
        },
        {
          name: this.$t("trip_request.form_5.input_4.values.value_3"),
          value: "Full board",
        },
      ],
    };
  },
  validations: {
    form: {
      singleRoom: {
        required,
      },
      meal: {
        required,
      },
      accommodations: {
        required,
        minLength: minLength(1),
      },
    },
  },
  computed: {
    ...mapGetters("request", ["step", "travelerDetails"]),
    ...mapGetters({
      numOfChildren: "request/numOfChildren",
      numOfAdults: "request/numOfAdults",
      currentStep: "request/currentStep",
      totalPages: "request/totalPages",
      isTravelersDetailsPage: "request/isTravelersDetailsPage",
      isTripDetailsPage: "request/isTripDetailsPage",
      isExperiencesPage: "request/isExperiencesPage",
      isInterestPage: "request/isInterestPage",
      isExperience: "request/isExperience",
      isAccommodation: "request/isAccommodation",
      selectedAccommodations: "request/selectedAccommodations",
      mealPlan: "request/mealPlan",
      singleRooms: "request/singleRooms",
      singleRoom: "request/singleRoom",
      tab: "request/tab",
      selectedAccomodations: "request/selectedAccomodations",
      firstSetting: "request/firstSetting"
    }),
    rooms() {
      //Check if children were selected
      let num = this.numOfChildren + this.numOfAdults;
      let arr = [];

      for (let i = 0; i <= num; i++) {
        arr.push({
          name: i,
          value: i,
        });
      }
      return arr;
    },
    roomErrMsg() {
      let msg;
      if (!this.$v.form.singleRoom.required) {
        msg = this.$t("trip_request.form_5.input_2.error");
      }
      return msg;
    },
    mealErrMsg() {
      let msg;
      if (!this.$v.form.meal.required) {
        msg = this.$t("trip_request.form_5.input_4.error");
      }
      return msg;
    },
    numOfTravelers() {
      return this.numOfAdults + this.numOfChildren;
    },
  },
  methods: {
    ...mapActions({
      setSingleRooms: "request/setSingleRooms",
      setSingleRoom: "request/setSingleRoom",
      setSelectedAccommodations: "request/setSelectedAccommodations",
      setMealPlan: "request/setMealPlan",
      decreasePage: "request/decreasePage",
      increasePage: "request/increasePage",
      setIsContactPage: "request/setIsContactPage",
      setFirstSetting: "request/setFirstSetting",
      setFormData: "request/setFormData"
    }),
    isSingleRoom(answer) {
      this.setFirstSetting(true);
      this.setSingleRoom(answer);
      this.setSingleRooms(undefined);
    },
    setAccommodation(ref) {
      let radioBox = this.getSelectedRadioBox(ref);
      let currentAccommodation = radioBox.value; //Get current radio box value

      //Check if item is already set in localStorage
      if (this.selectedAccommodations.length > 0) {
        let value = currentAccommodation;
        let array = this.selectedAccommodations;

        //If selected value already exists then remove it
        if (array.includes(value)) {
          for (let i = 0; i < array.length; i++) {
            let item = array[i];

            if (item === value) {
              array.splice(i, 1);
            }
          }
        } else {
          //Else add it to array
          array.push(value);
        }

        this.setSelectedAccommodations(array);
      } else {
        let value = [currentAccommodation];

        this.setSelectedAccommodations(value);
      }
    },
    changeNumberOfSingleRoom() {
      let select = this.$refs.singleRooms.$el.childNodes[1]; //Get single rooms select dropdown
      let currentRoom = select.firstElementChild.value; //Get current select dropdown value
      this.setSingleRooms(currentRoom) //Set value into vuex
      this.setSingleRoom(undefined);
    },
    changeMealPlan() {
      let select = this.$refs.mealPlan.$el.childNodes[1]; //Get meal plan select dropdown
      let currentMealPlan = select.firstElementChild.value; //Get current select dropdown value

      this.setMealPlan(currentMealPlan) //Set value into vuex
    },
    getSelectedRadioBox(ref) {
      let refs = this.$refs;
      let itemRefsName = ref;
      let radioBox;

      //Get selected radio box accommodation element
      for (let key in refs) {
        let item = refs[key]; // Get item from localStorage

        //Check if it is an array
        if (Array.isArray(item)) {
          //Check if it one of the accommodation items
          if (item[0].name === itemRefsName) {
            radioBox = item[0];
          }
        }
      }
      return radioBox;
    },
    routerPush(path) {
      this.$router.push({
        path: path,
        query: {
          q: this.$route.query.q,
          country: this.$route.query.country,
          lang: this.$route.query.lang,
        },
      });
    },
    goToPreviousPage() {
      this.decreasePage();
      this.routerPush("/request/interest");
    },
    submit() {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          
        this.increasePage();
        this.setIsContactPage(true);
        this.form.accommodations = this.form.accommodations.toString().replaceAll(",", ", ");

        this.setFormData(this.form);
        this.$store.commit("request/SET_ACCOMMODATION_DETAILS", this.form);
        this.routerPush("/request/contact");
      }
    },
  },
  beforeCreate() {
    if (this.isTravelersDetailsPage === false) {
      this.routerPush("/request");
    } else if (this.isTripDetailsPage === false) {
      this.routerPush("/request/traveler-details");
    } else if (
      this.isExperience === true && this.isExperiencesPage === false
    ) {
      this.routerPush("/request/trip-details");
    } else if (this.isInterestPage === false) {
      //Check if experiences where selected at the beginning of the form
      if (this.isExperience === true) {
        this.routerPush("/request/experiences");
      } else {
        this.routerPush("/request/trip-details");
      }
    } else if (this.isAccommodation === false) {
      this.routerPush("/request/interest");
    }
  },
  mounted() {

    if (this.numOfTravelers > 1) {
      //Set singleroom values
      this.form.singleRoom = this.singleRooms;

      //Get room select dropdown value on page refresh or when landing on page
      let roomSelect = this.$refs.singleRooms.$el.childNodes[1];
      let currentRoom = this.singleRooms;
      if (currentRoom) roomSelect.value = currentRoom;
    }

    //Set radiobuttons value
    if (this.$refs["singleRoom"] && this.firstSetting === true) {
      //Set singleroom values
      this.form.singleRoom = this.singleRoom;

      //Check second radio box
      if(this.singleRoom === 0)
        this.$refs["singleRoom"].$el.children[1].children[0].checked = true;

        //Check first radio box
      if(this.singleRoom === 1)
        this.$refs["singleRoom"].$el.children[0].children[0].checked = true;
    }
    
    //Set mealplan
    let mealPlanSelect = this.$refs.mealPlan.$el.childNodes[1];
    if (this.mealPlan.length > 0) {
      mealPlanSelect.value = this.mealPlan;
      this.form.meal = this.mealPlan;
    }

    //Set selected accommodations
    for (let accommodation of this.selectedAccommodations) {
      if (accommodation in this.$refs) {
        this.$refs[accommodation][0].checked = true; 
        this.form.accommodations.push(accommodation);
      }
    }
    
  },
};
</script>

<style scoped>
.navbar {
  @apply top-0 flex flex-wrap items-center w-full h-20 px-4 bg-white md:px-12;
}

.navbar-wrapper {
  @apply relative flex items-center justify-between w-full h-12 max-w-screen-md transform -translate-x-1/2 left-1/2;
}

.form {
  @apply w-full px-4 pb-48 mt-10 md:px-12;
}

.form-page {
  @apply relative max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.form-page-title {
  @apply mb-12 font-sans text-xl font-bold  sm:text-2xl text-black-base;
}

.bottom-nav {
  @apply fixed bottom-0 left-0 w-full p-4 bg-white border-t border-grey-base sm:mt-16;
  @apply block sm:hidden;
}

.bottom-nav-wrapper {
  @apply relative flex items-center justify-between max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.desktop-cta {
  @apply hidden sm:block h-12 px-8 w-full mt-8;
}

.form-checkbox {
  @apply w-5 h-5 border rounded border-black-lightest;
}

.error-msg {
  @apply mt-2;
}

.checkbox-group-label{
  @apply inline-block font-semibold text-base;
}

.label{
  @apply font-semibold text-black-base mb-2 inline-block;
}
</style>
